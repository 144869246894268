// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const selectRightsStore = (state, entity) => state.rights[entity]

export const createRightsSelector = entity =>
  function(state) {
    const store = selectRightsStore(state, entity)

    return store.rights.filter(right => !right.endsWith('_ALL'))
  }

export const createPseudoRightsSelector = entity =>
  function(state) {
    const store = selectRightsStore(state, entity)

    return store.rights.filter(right => right.endsWith('_ALL'))
  }
